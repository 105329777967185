// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from '@auth/redux/reducer'
import navbar from './navbar'
import layout from './layout'
import normalForm from './normalForm'
import formValidator from './formValidator'
import breadCrumbsReducer from './breadCrumbs'
import selectReducer from './selects'
import registrosReducer from './custom'
import tableStructureReducer from './tableStructureReducer'
import planningReducer from '../../views/routes/redux/reducer/planning'
import fileUpload from './fileUpload'
import permisosReducer from './permisos'
import alertReducer from './alert'

const rootReducer = combineReducers({
  registrosReducer,
  auth,
  navbar,
  layout,
  normalForm,
  fileUpload,
  selectReducer,
  planningReducer,
  formValidator,
  breadCrumbsReducer,
  tableStructureReducer,
  permisosReducer,
  alertReducer
})

export default rootReducer
