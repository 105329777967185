

export const formTypes = {
    inputChange: '[NormalForm] Input changed',
    cleanForm: '[NormalForm] Clean Values',
    initForm: '[NormalForm] Init Form structure',
    addRepeaterRegister: '[NormalForm] Add register for repeater',
    addDocumentRegister: '[NormalForm] Add document register',
    removeRepeaterRegister: '[NormalForm] Remove register for repeater',
    cleanRepeaterRegister: '[NormalForm] Clean register data',
    editRepeaterRegister: '[NormalForm] Edit register for repeater',
    fillFormData: '[NormalForm] Fill form data',
    sortRepeaterId: '[NormalForm] Sort repeater data',
    addExtraData: '[NormalForm] Adding Some Extra Data',
    cleanExceptId:'[NormalForm] Clean Values Except Id',
    removeRegisterFromTable:'[NormalForm] removeRegisterFromTable'
}