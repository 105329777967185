import { breadCrumbsType } from "../../types/breadCrumbs/types"

const initialState = []

const breadCrumbsReducer = (state = initialState, action) => {
    switch (action.type) {
        case breadCrumbsType.addChild:
            return [action.payload, ...state]

        case breadCrumbsType.removeChild:
            state.splice(0, 1)
            return [...state]

        case breadCrumbsType.clean:
            return initialState

        default:
            return state
    }
}
export default breadCrumbsReducer