import { selectTypes } from "../../types/selects/types"

// ** Initial State
const initialState = {
    UserType: [{ value: 1, label: 'Usuario' }, { value: 2, label: 'Conductor' }]
}

const selectReducer = (state = initialState, action) => {
    switch (action.type) {

        case selectTypes.addSelectOptions:
            return {
                ...state,
                [action.payload.key]: action.payload.options
            }

        case selectTypes.cleanSelectOptions:
            return initialState

        case selectTypes.destroySelectOptions:
            return {
                ...state,
                [action.payload.key]: action.payload.options
            }

        default:
            return state
    }
}

export default selectReducer
