export const planningTypes = {
    setFilter: '[Planning]: set filter',
    initPLanning: '[Planning] init planning',
    loadVehicles: '[Planning] vehicles loaded',
    addVehicle: '[Planning] vehicle added',
    removeVehicle: '[Planning] vehicle removed',
    updateColumns: '[Planning] Columns Updated',
    loadServices: '[Planning] Services loaded',
    cleanPlanning: '[Planning] Ceaning',
    loadingPlanning: '[Planning] Loading Planning'
}