import { formTypes } from "../../types/permisos/types"

// ** Initial State
const initialState = {
    Customers: { read: true, insert: true, update: true, delete: true },
    Centers: { read: true, insert: true, update: true, delete: true },
    Subcontractors: { read: true, insert: true, update: true, delete: true },
    Vendors: { read: true, insert: true, update: true, delete: true },
    Vehicles: { read: true, insert: true, update: true, delete: true },
    Carriers: { read: true, insert: true, update: true, delete: true },
    Items: { read: true, insert: true, update: true, delete: true },
    Containers: { read: true, insert: true, update: true, delete: true },
    Services: { read: true, insert: true, update: true, delete: true },
    Incidences: { read: true, insert: true, update: true, delete: true },
    Tonnage: { read: true },
    Weight: { read: true, insert: true, update: true, delete: true, actions: true },
    Operations: { read: true, insert: true, update: true, delete: true },
    Roles: { read: true, insert: true, update: true, delete: true },
    Users: { read: true, insert: true, update: true, delete: true },
    Setup: { read: true, insert: true, update: true, delete: true },
    Logs: { read: true },
    DriverApp: { read: true, insert: true, update: true, actions: true },
    Ots: { read: true, delete: true, actions: true },
    Routes: { read: true, insert: true, update: true, delete: true },
    WeightLocation: { read: true, actions: true },
    RoutesTracing: { read: true },
    Alerts: { read: true, actions: true },
    Rates: { read: true, insert: true, update: true, delete: true }
}

const permisosReducer = (state = initialState, action) => {
    switch (action.type) {
        case formTypes.SwitchPermission:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    [action.payload.permiso]: action.payload.value
                }
            }
        case formTypes.SwitchPermissionAll:
            const newPermision = Object.entries(state[action.payload.key]).map((el) => {
                return { id: [el[0]], value: action.payload.value }
            })

            const newP = newPermision.reduce(
                (obj, item) => Object.assign(obj, { [item.id]: item.value }), {})

            return {
                ...state,
                [action.payload.key]: {
                    ...newP
                }
            }
        case formTypes.FillPermision:
            return {
                ...action.payload
            }
        case formTypes.CleanPermisions:
            return { ...initialState }
        default:
            return state
    }
}

export default permisosReducer
