import { setVisualDateTime } from "../../../../utility/helpers/dates/visualDate.js"
import { planningTypes } from "../types/planning.js"

const initialState = {
    columns: {
        services: {
            name: 'Servicios',
            items: []
        }

    },
    vehicles: [],
    filters: {
        idGarbageType: '',
        itineranceTypes: '',
        planningDay: setVisualDateTime(Date.now(), '/'),
        idDestinatary: { label: 'Cirera', value: 0 },
        inOut: { label: 'Entrada', value: 1 }
    },
    routes: []

}

const planningReducer = (state = initialState, action) => {
    switch (action.type) {
        case planningTypes.cleanPlanning:
            return initialState

        case planningTypes.setFilter:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.filterName]: action.payload.value
                }
            }
        case planningTypes.loadVehicles:
            return {
                ...state,
                vehicles: [...action.payload]
            }
        case planningTypes.addVehicle:
            state.vehicles[action.payload.index] = {
                ...state.vehicles[action.payload.index],
                selected: true
            }
            return {
                ...state,
                columns: {
                    ...state.columns,
                    [action.payload.vehicle.id]: {
                        name: action.payload.vehicle.plateNumber,
                        capacity: action.payload.vehicle.capacity,
                        types: action.payload.vehicle.types,
                        idVehicle: action.payload.vehicle.id,
                        idDriver: action.payload.vehicle.idDriver.id,
                        items: []
                    }
                }
            }
        case planningTypes.removeVehicle:
            state.vehicles[action.payload.index] = {
                ...state.vehicles[action.payload.index],
                selected: false
            }
            state.columns['services'].items = [...state.columns['services'].items, ...state.columns[action.payload.columnKey].items]
            delete state.columns[action.payload.columnKey]
            return { ...state }
        case planningTypes.updateColumns:
            return {
                ...state,
                columns: {
                    ...action.payload
                }
            }
        case planningTypes.loadServices:
            state.columns['services'].items = [...action.payload]
            return { ...state }

        case planningTypes.addRoutes:
            state['routes'] = [...action.payload]
            return { ...state }

        case planningTypes.loadingPlanning:
            return { ...action.payload }
        default: return state
    }
}

export default planningReducer