import { tableStructureTypes } from "../../types/tableStructure/types"

// ** Initial State
const initialState = {}
  
const tableStructureReducer = (state = initialState, action) => {
    switch (action.type) {
        case tableStructureTypes.addtableOptions:

            return {
                ...state,
                action: action.table
            }
    
        default:
            return state
    }
}
  
export default tableStructureReducer
  