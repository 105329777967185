export const setVisualDate = (millis, separator = '-') => {
    const date = new Date(millis)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()

    return `${day}${separator}${month}${separator}${year}`

}



export const setVisualDateTime = (millis, separator = '-') => {
    const date = new Date(millis)
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')

    return `${setVisualDate(millis, separator)} ${hours}:${minutes}:${seconds}`
}